import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import { FdrPageComponents } from 'fdr/components/local/fdr-page-components';
import { FdrStyledTextBlock } from 'fdr/components/local/fdr-styled-text-block';

export const FdrStaticInformationWrapper = styled(FdrStyledTextBlock)(
  sx2CssThemeFn({
    py: [5, '75px'],
    px: [4, 0],
  })
);

export const FdrStaticPageComponents = styled(FdrPageComponents)(
  sx2CssThemeFn({
    '.media-gallery': {
      mb: [5, '75px'],
      '.slider-control-centerleft': {
        left: '25px!important',
      },
      '.slider-control-centerright': {
        right: '25px!important',
      },
    },
    pb: [null, '75px'],
  })
);
