import { NextPage } from 'next';
import React from 'react';
import { Container } from 'components/domain/container';
import { FdrHero } from 'fdr/components/candidate/fdr-hero';
import {
  FdrPageComponents,
  fdrPageComponentsPlaceholder,
  useFdrPageComponentsContext,
  fdrCommonPageComponentsVariables,
} from 'fdr/components/local/fdr-page-components';
import {
  FdrStaticInformationWrapper,
  FdrStaticPageComponents,
} from 'fdr/components/local/fdr-static/fdr-static.styles';
import { useFdrStaticPageComponentsQuery } from 'fdr/schemas/query/static/page-components/fdr-static-page-components.generated';
import { FdrStaticPageContentQuery } from 'fdr/schemas/query/static/page-content/fdr-static-page-content.generated';

export { getServerSideProps } from 'components/page/static/static.gssp';

const StaticPage: NextPage<{
  url: string;
  content: FdrStaticPageContentQuery['fdrStaticPage'];
}> = ({ url, content }) => {
  const {
    hero,
    removeHeaderFooter,
    textBgColor,
    textFgColor,
    textBgImage,
    text,
    title,
  } = content || {};

  const context = useFdrPageComponentsContext();
  const { data: pageComponents, loading: pageComponentsLoading } =
    useFdrStaticPageComponentsQuery({
      variables: {
        input: {
          id: url,
        },
        ...fdrCommonPageComponentsVariables,
        ...context,
      },
    });

  const components =
    pageComponents?.fdrStaticPage?.pageComponents?.components || [];

  if (removeHeaderFooter) {
    return pageComponentsLoading ? (
      <></>
    ) : (
      <FdrPageComponents components={components} />
    );
  }

  return (
    <>
      {hero ? <FdrHero heroImage={hero} /> : null}
      <Container>
        {title || text ? (
          <FdrStaticInformationWrapper
            title={title}
            text={text}
            fontColor={textFgColor}
            backgroundColor={textBgColor}
            backgroundImage={textBgImage}
          />
        ) : null}
      </Container>
      {pageComponentsLoading && !pageComponents ? (
        fdrPageComponentsPlaceholder
      ) : (
        <FdrStaticPageComponents components={components} />
      )}
    </>
  );
};

export default StaticPage;
